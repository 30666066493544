import styled from "styled-components";

const Title = styled.h1`
  margin: 10px 0;
  line-height: 1.15;
  font-size: 4rem;
  text-align: center;
`;

export default Title;
